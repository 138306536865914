const icons = [

  {
    "value": "el-icon-edit",
    "selectColor": ""
  }, {
    "value": "el-icon-search",
    "selectColor": ""
  },
  {
    "value": "el-icon-platform-eleme",
    "selectColor": ""
  }, {
    "value": "el-icon-delete-solid",
    "selectColor": ""
  }, {
    "value": "el-icon-s-tools",
    "selectColor": ""
  }, {
    "value": "el-icon-setting",
    "selectColor": ""
  }, {
    "value": "el-icon-user-solid",
    "selectColor": ""
  }, {
    "value": "el-icon-user",
    "selectColor": ""
  }, {
    "value": "el-icon-phone",
    "selectColor": ""
  }, {
    "value": "el-icon-phone-outline",
    "selectColor": ""
  }, {
    "value": "el-icon-more",
    "selectColor": ""
  }, {
    "value": "el-icon-more-outline",
    "selectColor": ""
  }, {
    "value": "el-icon-star-on",
    "selectColor": ""
  }, {
    "value": "el-icon-s-goods",
    "selectColor": ""
  }, {
    "value": "el-icon-goods",
    "selectColor": ""
  }, {
    "value": "el-icon-warning",
    "selectColor": ""
  }, {
    "value": "el-icon-warning-outline",
    "selectColor": ""
  }, {
    "value": "el-icon-question",
    "selectColor": ""
  }, {
    "value": "el-icon-info",
    "selectColor": ""
  }, {
    "value": "el-icon-remove",
    "selectColor": ""
  }, {
    "value": "el-icon-circle-plus",
    "selectColor": ""
  }, {
    "value": "el-icon-success",
    "selectColor": ""
  }, {
    "value": "el-icon-error",
    "selectColor": ""
  }, {
    "value": "el-icon-zoom-in",
    "selectColor": ""
  }, {
    "value": "el-icon-zoom-out",
    "selectColor": ""
  }, {
    "value": "el-icon-remove-outline",
    "selectColor": ""
  }, {
    "value": "el-icon-circle-plus-outline",
    "selectColor": ""
  }, {
    "value": "el-icon-circle-check",
    "selectColor": ""
  }, {
    "value": "el-icon-circle-close",
    "selectColor": ""
  }, {
    "value": "el-icon-s-help",
    "selectColor": ""
  }, {
    "value": "el-icon-help",
    "selectColor": ""
  }, {
    "value": "el-icon-minus",
    "selectColor": ""
  }, {
    "value": "el-icon-plus",
    "selectColor": ""
  }, {
    "value": "el-icon-check",
    "selectColor": ""
  }, {
    "value": "el-icon-close",
    "selectColor": ""
  }, {
    "value": "el-icon-picture",
    "selectColor": ""
  }, {
    "value": "el-icon-picture-outline",
    "selectColor": ""
  }, {
    "value": "el-icon-picture-outline-round",
    "selectColor": ""
  }, {
    "value": "el-icon-upload",
    "selectColor": ""
  }, {
    "value": "el-icon-upload2",
    "selectColor": ""
  }, {
    "value": "el-icon-download",
    "selectColor": ""
  }, {
    "value": "el-icon-camera-solid",
    "selectColor": ""
  }, {
    "value": "el-icon-camera",
    "selectColor": ""
  }, {
    "value": "el-icon-video-camera-solid",
    "selectColor": ""
  }, {
    "value": "el-icon-video-camera",
    "selectColor": ""
  }, {
    "value": "el-icon-message-solid",
    "selectColor": ""
  }, {
    "value": "el-icon-bell",
    "selectColor": ""
  }, {
    "value": "el-icon-camera",
    "selectColor": ""
  }, {
    "value": "el-icon-s-custom",
    "selectColor": ""
  }, {
    "value": "el-icon-s-opportunity",
    "selectColor": ""
  }, {
    "value": "el-icon-s-data",
    "selectColor": ""
  }, {
    "value": "el-icon-s-check",
    "selectColor": ""
  }, {
    "value": "el-icon-s-grid",
    "selectColor": ""
  }, {
    "value": "el-icon-menu",
    "selectColor": ""
  }, {
    "value": "el-icon-share",
    "selectColor": ""
  }, {
    "value": "el-icon-truck",
    "selectColor": ""
  }, {
    "value": "el-icon-ship",
    "selectColor": ""
  }, {
    "value": "el-icon-basketball",
    "selectColor": ""
  }, {
    "value": "el-icon-football",
    "selectColor": ""
  }, {
    "value": "el-icon-soccer",
    "selectColor": ""
  }, {
    "value": "el-icon-baseball",
    "selectColor": ""
  }, {
    "value": "el-icon-wind-power",
    "selectColor": ""
  }, {
    "value": "el-icon-light-rain",
    "selectColor": ""
  }, {
    "value": "el-icon-lightning",
    "selectColor": ""
  }, {
    "value": "el-icon-heavy-rain",
    "selectColor": ""
  }, {
    "value": "el-icon-sunrise",
    "selectColor": ""
  }, {
    "value": "el-icon-sunrise-1",
    "selectColor": ""
  }, {
    "value": "el-icon-sunset",
    "selectColor": ""
  }, {
    "value": "el-icon-sunny",
    "selectColor": ""
  }, {
    "value": "el-icon-cloudy",
    "selectColor": ""
  }, {
    "value": "el-icon-cloudy-and-sunny",
    "selectColor": ""
  }, {
    "value": "el-icon-moon",
    "selectColor": ""
  }, {
    "value": "el-icon-moon-night",
    "selectColor": ""
  }, {
    "value": "el-icon-dish",
    "selectColor": ""
  }, {
    "value": "el-icon-dish-1",
    "selectColor": ""
  }, {
    "value": "el-icon-food",
    "selectColor": ""
  }, {
    "value": "el-icon-collection",
    "selectColor": ""
  }

]



export default {
  icons
}