<template>
  <div class="box">
    <div class="main-menu">

      <el-tabs type="border-card" style="height:100%" @tab-click="handleClick">
        <el-tab-pane label="菜单">
          <el-container>
            <el-header class="main-menu-header">
              <el-card class="cardMargin">
                <div class="main-menu-header-buttion">
                  <el-button round type="primary" icon="el-icon-circle-plus-outline" size="small" @click="add(null)">
                    新增菜单
                  </el-button>

                </div>
              </el-card>

            </el-header>
            <el-card class="box-card" style="margin-top: 15px">
              <el-table size="small" :data="tableData" class="cardMargin" row-key="id" border :default-expand-all="false"
                :tree-props="{ children: 'childList', hasChildren: 'hasChildren' }">
                <el-table-column prop="menuName" align="center" label="按钮名" width="300"></el-table-column>
                <el-table-column prop="typeDisplay" align="center" label="类型" width="200"></el-table-column>
                <el-table-column prop="code" align="center" label="标识码" width="150"></el-table-column>
                <el-table-column prop="icon" align="center" label="图片icon" width="300"></el-table-column>
                <el-table-column label="操作" align="center" width="450">
                  <template slot-scope="props">
                    <el-button round v-if="props.row.type == 1" type="primary" icon="el-icon-circle-plus-outline"
                      size="mini" @click="add(props.row)"> 新增页面</el-button>
                    <el-button round v-if="props.row.type == 2" type="primary" icon="el-icon-circle-plus-outline"
                      size="mini" @click="add(props.row)">新增按钮</el-button>
                    <el-button round type="success" icon="el-icon-edit" size="mini" @click="edit(props.row)">编辑
                    </el-button>
                    <el-button round v-if="props.row.childList.length == 0" icon="el-icon-delete" type="danger"
                      size="mini" @click="del(props.row)">移除</el-button>
                  </template>
                </el-table-column>

              </el-table>
            </el-card>

          </el-container>
        </el-tab-pane>
        <el-tab-pane label="增值权限">
          <el-button  class="button-new-tag" size="small" @click="tagOpen">+ 新增</el-button>
          <div style="padding:10px 0px 10px 0px" v-for="(row, index)  in accountsGroup" :key="index">
            <el-divider content-position="left">{{ index }}</el-divider>
            <el-tag :hidden="tag.key!=index" effect="dark" :type="tag.isEdit == 0 ? 'info' : 'warning'" v-for="tag in dynamicTags"
              :closable="tag.isEdit == 1" :key="tag.id" :disable-transitions="true" @close="handleClose(tag.id)">
              {{ tag.name }}
            </el-tag>
           
          </div>
        </el-tab-pane>

      </el-tabs>

    </div>
    <div>
      <!-- Form -->
      <el-dialog :title="title" :visible.sync="dialogFormVisible">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm"
          label-position="left">
          <el-form-item label="名称" prop="menuName">
            <el-input v-model="ruleForm.menuName"></el-input>
          </el-form-item>
          <el-form-item label="类型" prop="type">
            <el-select v-model="ruleForm.type" clearable placeholder="请选择" disabled>
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="样式" prop="style" :hidden="ruleForm.type != 3 || ((title == '新增') && ruleForm.type == 2)">
            <el-select v-model="ruleForm.style" clearable placeholder="请选择" @change="onChangeStyle">
              <el-option v-for="item in styleArray" :key="item.value" :label="item.label" :value="item.key">
              </el-option>
            </el-select>
            <el-button style="margin-left:30px" size="small" round :type="style">{{ styleDisplay }}</el-button>
          </el-form-item>


          <el-form-item label="标识码" prop="code" :hidden="ruleForm.type != 3 || ((title == '新增') && ruleForm.type == 2)">
            <el-select allow-create filterable v-model="ruleForm.code" clearable placeholder="请选择">
              <el-option v-for="item in codeArray" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>


          <el-form-item label="图标" prop="icon">
            <el-input v-model="ruleForm.icon" @focus="iconFocus(ruleForm.icon)"></el-input>
          </el-form-item>

          <el-form-item label="地址" prop="path" placeholder="地址" :hidden="ruleForm.type == 3">
            <el-input v-model="ruleForm.path"></el-input>
          </el-form-item>

          <el-form-item label="排序" prop="sort" placeholder="排序">
            <el-input v-model="ruleForm.sort"></el-input>
          </el-form-item>

          <el-form-item style="text-align: right; margin: 0">
            <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
            <el-button @click="resetForm('ruleForm')">重置</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>

      <el-dialog title="请选择图标" :visible.sync="dialogIconVisible">
        <ul class="icon-list">
          <li v-for="(item, index) in icons" @click="iconClick(item.value)" :key="index" :style="item.selectColor">
            <span>
              <i :class="item.value"></i>
              <span class="icon-name">{{ item.value }}</span>
            </span>
          </li>
        </ul>

      </el-dialog>
    </div>
    <el-dialog title="新增" :visible.sync="dialogVisible" width="20%"
    :before-close="handleClose">
    <span>
      
      <el-form ref="form"  label-width="80px">
    
        <el-form-item label="公司名称">
          <el-select v-model="selectValue"  placeholder="请选择" >
          <el-option
            v-for="item in selectList"
            :key="item.label"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>

      </el-form-item>
      <el-form-item label="发布标签">
        <el-input style="width: 60%;" maxlength="20" minlength="1"  show-word-limit v-model="inputValue"></el-input>
      </el-form-item>
      </el-form>
    </span>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="addPower">确 定</el-button>
    </span>
  </el-dialog>
  </div>
  
</template>
<script>
import iconData from "@/assets/js/icon.js";
import select from "@/assets/js/select.js";
import menu from "@/api/menu.js";
import power from "@/api/powerMenu.js";
import serverApi from "@/api/server.js";

export default {
  data() {
    return {
      styleDisplay: "",
      style: "",
      icons: iconData.icons,
      tableData: [],
      page: null,
      dialogFormVisible: false,
      dialogIconVisible: false,
      title: "新增",
      options: select.pageTypeArray,
      styleArray: select.styleArray,
      codeArray: select.codeButtionArray,
      ruleForm: {
        pid: "",
        id: "",
        menuName: "",
        path: "",
        icon: "",
        type: 1,
        stype: '',
        code: '',
        sort: 1
      },
      rules: {
        menuName: [
          { required: true, message: "请输入名称", trigger: "blur" },
          {
            min: 1,
            max: 10,
            message: "长度在 1 到 10 个字符",
            trigger: "blur",
          },
        ],
        //path: [{ required: true, message: "请输入地址", trigger: "blur" }],
        type: [{ required: true, message: "请选择类型", trigger: "change" }],
        icon: [{ required: true, message: "请输入图标", trigger: "blur" }],
      },
      formLabelWidth: "120px",
      dynamicTags: [],
      inputValue: '',
      selectValue: '',
      accountsGroup: [],
      selectList:[],
      dialogVisible:false
    };
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() {
    this.getMenuPage();
    this.getAccounts();
  },
  computed: {},
  //生命周期 - 挂载完成（访问DOM元素）
  methods: {
    getAccounts() {
      let params = {};
      this.$get(serverApi.getAccounts, params).then((res) => {
        if (res.success && res.statusCode == 200) {
          let serverAcounts = res.data;

          const serverGroupedBy = {};
          for (const item of serverAcounts) {
            if (serverGroupedBy[item.group]) {
              serverGroupedBy[item.group].push(item);
            } else {
              serverGroupedBy[item.group] = [item];
            }
          }
          this.accountsGroup = serverGroupedBy;
          console.log(this.accountsGroup)
          this.selectList=[];
      
          for (var key in this.accountsGroup) {  
           let params = {value:key,label:key}
           this.selectList.push(params);
          
          }
        }
      });
    },
    getMenuPage() {
      this.page = { pageIndex: 1, pageSize: 10 };
      this.$post(menu.page, this.page).then((res) => {
        if (res.success && res.statusCode == 200) {
          this.tableData = res.data.records;
        }
      });
    },
    
    tagOpen(){
      this.dialogVisible=true
    },
    add(row) {
      this.dialogFormVisible = true;
      this.title = "新增";
      if (this.$refs["ruleForm"] !== undefined) {
        this.$refs["ruleForm"].resetFields();
      }
      if (row == null) {
        this.ruleForm.pid = 0;
        this.ruleForm.id = 0;
      } else {
        if (row.type == 1) {
          this.ruleForm.type = 2;
        } else if (row.type == 2) {
          this.ruleForm.type = 3;
        }
        this.ruleForm.pid = row.id;
        this.ruleForm.id = 0;
      }
    },
    edit(row) {
      this.dialogFormVisible = true;
      this.title = "编辑";
      this.ruleForm.id = row.id;
      this.ruleForm.menuName = row.menuName;
      this.ruleForm.path = row.path;
      this.ruleForm.type = row.type;
      this.ruleForm.icon = row.icon;
      this.ruleForm.pid = row.pid;
      this.ruleForm.style = row.style;
      this.ruleForm.code = row.code;
      this.ruleForm.sort = row.sort;
    },
    del(row) {
      //todo 提醒
      this.$del(menu.del, { id: row.id }).then((res) => {
        if (res.success && res.statusCode == 200) {
          this.getMenuPage();
        }
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.ruleForm.id == null) {
            this.ruleForm.id == 0;
          }

          if (this.ruleForm.id == 0) {
            this.$post(menu.add, this.ruleForm).then((res) => {
              if (res.success && res.statusCode == 200) {
                this.getMenuPage();
              }
            });
          } else {
            this.$post(menu.edit, this.ruleForm).then((res) => {
              if (res.success && res.statusCode == 200) {
                this.getMenuPage();
              }
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
        this.dialogFormVisible = false;
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    //选择图标获取焦点事件
    iconFocus(value) {
      this.icons.forEach((f) => {
        if (f.value == value) {
          f.selectColor = "background: greenyellow";
        } else {
          f.selectColor = "";
        }
      });
      this.dialogIconVisible = true;
    },
    //选择图标点击事件
    iconClick(value) {
      this.ruleForm.icon = value;
      this.dialogIconVisible = false;
    },
    onChangeStyle(key) {
      let item = this.styleArray.filter(f => f.key == key);
      if (item.length > 0) {
        this.styleDisplay = item[0].label;
        this.style = item[0].value;
      }
    },
    //增值权限功能
    handleClose(tag) {
      this.$confirm('此操作将永久删除该配置, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {

        let form = { id: tag };
        this.$del(power.del, form).then((res) => {
          if (res.success && res.statusCode == 200) {

            this.getPowerList();
          }

        });
      }).catch(() => {

      });

    },
    
    handleClick(event) {
      if (event.label == '增值权限') {
        this.getPowerList();
      }
    },
    //获取增值菜单列表
    getPowerList() {
      this.$get(power.list, {}).then((res) => {
        if (res.success && res.statusCode == 200) {
          this.dynamicTags = res.data;
        }
      });
    },
    addPower() {
    
      let form = { id: null,key:this.selectValue, name: this.inputValue, type: 1 };
     
      this.$post(power.add, form).then((res) => {
        if (res.success && res.statusCode == 200) {
          this.dialogVisible=false;
          this.getPowerList();
        }
      });
    }

  },
};
</script>
<style lang="scss" scoped>
.main-menu .el-main {
  padding: 5px;

}

.main-menu .el-header {
  padding: 0px;
}

.main-menu-header-buttion {

  display: flex;
  justify-content: space-between;
}

.cardMargin {
  width: 100%;
  margin-top: 0px
}

.icon-list {
  overflow: hidden;
  list-style: none;
  padding: 0 !important;
  border: 1px solid #eaeefb;
  border-radius: 4px;
}

.icon-list li {
  float: left;
  width: 16.66%;
  text-align: center;
  height: 90px;
  line-height: 10px;
  color: #666;
  font-size: 13px;
  border-right: 1px solid #eee;
  border-bottom: 1px solid #eee;
  margin-right: -1px;
  margin-bottom: -1px;
  padding-top: 15px;
  cursor: pointer;
}

.icon-list li:hover {
  cursor: pointer;

  i {
    color: red;
  }

  span {
    color: red;
  }
}

.icon-list li span {
  line-height: normal;
  font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB,
    Microsoft YaHei, SimSun, sans-serif;
  color: #99a9bf;
  transition: color 0.15s linear;
}

.icon-list li i {
  display: block;
  font-size: 32px;
  margin-bottom: 15px;
  color: #606266;
  transition: color 0.15s linear;
}

.el-tag+.el-tag {
  margin-left: 10px;
}

.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}

.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
</style>
