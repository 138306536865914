//按钮样式集合
const styleArray = [{
    key: 1,
    value: 'def',
    label: "默认"
  },
  {
    key: 2,
    value: 'primary',
    label: "主要"
  },
  {
    key: 3,
    value: 'success',
    label: "成功"
  }, {
    key: 4,
    value: 'info',
    label: "信息"
  }, {
    key: 5,
    value: 'warning',
    label: "警告"
  }, {
    key: 6,
    value: 'danger',
    label: "危险"
  }
]

//页面按钮下拉集合
const pageTypeArray = [{
    value: 1,
    label: "菜单",
  },
  {
    value: 2,
    label: "页面",
  },
  {
    value: 3,
    label: "按钮",
  },
]
//按钮集合
const codeButtionArray = [{
    value: 'add',
    label: "新增"
  },
  {
    value: 'edit',
    label: "编辑"
  },
  {
    value: 'del',
    label: "删除"
  }, {
    value: 'export',
    label: "导出"
  }, {
    value: 'role',
    label: "角色"
  }, {
    value: 'menu',
    label: "菜单"
  },
  {
    value: 'tab',
    label: "Tab栏"
  }
]


export default {
  pageTypeArray,
  styleArray,
  codeButtionArray
}